import React from "react";
import team001 from "../../images/t4.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Dean Robinson</h3>
    <h4 className="">Director</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          Dean holds numerous roles including Chief Financial Officer of the ASX
          Listed company Teaminvest Private Group Ltd (ASX:TIP), Director
          Agriventis Technologies and Director Speckle Park Group (SPKL).
        </p>
        <p>
          Prior to joining Teaminvest, Dean worked as a Director of Mergers and
          Acquisitions with KPMG. In this role he led the growth and development
          of the Greater Western Sydney team. Prior to his career in Corporate
          Finance / Investment Banking, Dean reached the pinnacle of
          Professional Sport as both a coach and athlete, in a career spanning
          over 20 years.{" "}
        </p>
        <p>
          Dean was raised in country NSW attending an Agricultural High School
          and University studying Agriculture and Resource Economics. Dean
          pursued his love of the country through Rodeo which led him to the USA
          where he qualified for World Finals in 1997 as a Steer Wrestler.{" "}
        </p>
        <p>
          Dean holds three Masters degrees in Applied Finance from Macquarie
          University Applied Finance Centre, a Senior Executive MBA from
          University of Melbourne – Melbourne Business School, and a Masters in
          Physiotherapy from University of Sydney.
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
